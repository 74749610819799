export function getPlatformName(platform: number): string {
  switch (platform) {
    case 1:
      return "Airbnb";
    case 2:
      return "Booking";
    default:
      return "Out";
  }
}
