import { useCallback, useEffect, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Grid from "../../lib/organisms/Grid";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { fetchOptions } from "../../hooks/useAuth";
import { apiUrl } from "../../layouts/userLayout";
import { decodeErrorResponse } from "../../utils/errors";
import { errorPopup, successPopup } from "../../utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import EditApartmentRatioModal from "../../lib/organisms/EditApartmentRatioModal";
import { useUserContext } from "../../hooks/userUserContext";
import { dateToString } from "../../utils/periods";
import { Convert, ManagementFeeRatio } from "../../models/responses";

interface ExtendedActionsRendererProps extends CustomCellRendererProps {
  onEdit: (id: number) => void;
}

export const ActionsRenderer: React.FC<ExtendedActionsRendererProps> = (params: ExtendedActionsRendererProps) => {
  return (
    <div>
      <FontAwesomeIcon
        style={{ cursor: "pointer" }}
        className={"text-info-emphasis"}
        icon={faEdit}
        onClick={function () {
          console.log(params);
          return params.onEdit(params.data.id);
        }}
      />
    </div>
  );
};

const ManagementFeeRatiosPage: React.FC = () => {
  const { apartmentIdMap } = useUserContext().context;
  const [ratios, setRatios] = useState<ManagementFeeRatio[]>([]);
  const [apartmentToEdit, setApartmentToEdit] = useState<ManagementFeeRatio | undefined>(undefined);
  const gridRef = useRef<AgGridReact | any>();
  const [showEditRatiosModal, setShowEditRatiosModal] = useState(false);
  const gridName = "Latest Management Fee Ratios";

  const editManagementFeeRatio = useCallback(
    (id: number) => {
      const apartmentRatio: ManagementFeeRatio = ratios.find((ratio) => ratio.id === id)!;
      setApartmentToEdit(apartmentRatio);
      setShowEditRatiosModal(true);
    },
    [ratios],
  );

  const columnDefinitions: ColDef[] = [
    {
      field: "apartmentId",
      headerName: "Apartment",
      valueGetter: (params) => {
        const apartmentName = apartmentIdMap.get(params.data.apartmentId)?.name;
        if (!apartmentName) {
          console.log("No apartment name found for id: " + params.data.apartmentId);
        }
        return apartmentName;
      },
      flex: 1,
    },
    {
      field: "managementFeeRatio",
      headerName: "ManagementFee Ratio",
      valueFormatter: (params) => `${(params.value * 100).toFixed(0)}%`,
    },
    { field: "fromDate", headerName: "Effective From" },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        onEdit: (apartmentId: number) => editManagementFeeRatio(apartmentId),
      },
    },
  ];

  useEffect(() => {
    fetch(`${apiUrl}/api/admin/apartments/management-fee-ratios`, fetchOptions)
      .then((response) => response.text())
      .then((data) => {
        let ratios = Convert.toGetManagementFeeRatiosResponse(data).managementFeeRatios;
        setRatios(ratios);
      });
  }, []);

  const handleModalSubmitted = useCallback(
    (apartmentToEdit: ManagementFeeRatio, managementFeeRatio: number, fromDate: Date) => {
      const apartmentId = apartmentToEdit?.apartmentId;
      const updatesExisting = dateToString(apartmentToEdit?.fromDate) === dateToString(fromDate);
      console.log(apartmentToEdit);
      console.log(apartmentToEdit?.fromDate);
      console.log(fromDate);
      const url = updatesExisting
        ? `${apiUrl}/api/admin/apartments/${apartmentId}/management-fee-ratios/${apartmentToEdit?.id}`
        : `${apiUrl}/api/admin/apartments/management-fee-ratios`;
      const method = updatesExisting ? "PUT" : "POST";
      fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          apartmentId: apartmentId,
          managementFeeRatio: managementFeeRatio,
          fromDate: dateToString(fromDate),
        }),
      })
        .then((response) => {
          if (!response.ok) return decodeErrorResponse(response);
          return response.text();
        })
        .then((data) => {
          let updatedManagementFeeRatio: ManagementFeeRatio =
            Convert.toGetManagementFeeRatioResponse(data).managementFeeRatio;
          let updatedRatios: ManagementFeeRatio[] = ratios.map((ratio: ManagementFeeRatio) =>
            ratio.apartmentId === updatedManagementFeeRatio.apartmentId ? updatedManagementFeeRatio : ratio,
          );
          setRatios(updatedRatios);
          successPopup("Management Fee ratio updated successfully");
          setShowEditRatiosModal(false);
        })
        .catch((error) => {
          errorPopup("Failed to update ratio:" + error.message);
        });
    },
    [ratios],
  );

  const handleModalClosed = useCallback(() => {
    setApartmentToEdit(undefined);
    setShowEditRatiosModal(false);
  }, []);

  return (
    <>
      <EditApartmentRatioModal
        show={showEditRatiosModal}
        handleClose={handleModalClosed}
        handleSubmitted={(managementFeeRatio, fromDate) =>
          handleModalSubmitted(apartmentToEdit!, managementFeeRatio, fromDate)
        }
        modalTitle={"Management Fee Ratio"}
        apartmentName={apartmentIdMap.get(apartmentToEdit?.apartmentId || 0)?.name || ""}
        ratio={apartmentToEdit?.managementFeeRatio || 0}
        fromDate={apartmentToEdit?.fromDate || new Date()}
      />
      <Grid<ManagementFeeRatio>
        columnDefs={columnDefinitions}
        gridName={gridName}
        initialRowData={ratios}
        gridRef={gridRef}
        canExport={false}
        getRowId={(params) => params.data.apartmentId.toString()}
      />
    </>
  );
};
export default ManagementFeeRatiosPage;
