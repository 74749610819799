import React, { useCallback, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

interface FileUploadModalProps {
  show: boolean;
  handleClose: () => void;
  handleFileUpload: (file: File) => void;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ show, handleClose, handleFileUpload }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Only set the uploaded file here, don't call handleFileUpload
    setUploadedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Call handleFileUpload here with the uploaded file
    if (uploadedFile) {
      handleFileUpload(uploadedFile);
    }
    setUploadedFile(null); // Clear the uploaded file when the form is submitted
    handleClose();
  };

  const handleCloseModal = () => {
    setUploadedFile(null); // Clear the uploaded file when the modal is closed
    handleClose();
  };

  const deleteFile = () => {
    setUploadedFile(null);
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <div {...getRootProps({ className: "dropzone rounded mb-4 dz-clickable mt-4 mb-4" })}>
            <input {...getInputProps()} />
            <p className="dropzone-text">
              {isDragActive ? "Drop the files here ..." : "Drag 'n' drop some files here, or click to select files"}
            </p>
            {uploadedFile && (
              <div className="dz-preview dz-file-preview">
                <button className="dz-remove" onClick={deleteFile}>
                  x
                </button>
                <div className="dz-details">
                  <div className="dz-size">
                    <span data-dz-size="">
                      <strong>{(uploadedFile.size / 1024 / 1024).toFixed(1)}</strong> MB
                    </span>
                  </div>
                  <div className="dz-filename">
                    <span data-dz-name="">{uploadedFile.name}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={!uploadedFile}>
            Upload
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FileUploadModal;
