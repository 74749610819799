import React, { useCallback, useEffect, useRef, useState } from "react";
import { apiUrl } from "../../layouts/adminLayout";
import { Convert, Reservation } from "../../models/responses";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { GridDropdownFilter } from "../../lib/organisms/Grid";
import { AgGridReact } from "ag-grid-react";
import { errorPopup, successPopup, successPopupWithHtml } from "../../utils/alerts";
import Swal from "sweetalert2";
import { AdminContextType, useUserContext } from "../../hooks/userUserContext";
import ReservationsPage, { ReservationRow } from "../reservations";
import { PeriodName, Platform } from "../../models";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { fetchOptions } from "../../hooks/useAuth";
import FileUploadModal from "../../lib/organisms/NewFileUploadModal";
import { decodeErrorResponse } from "../../utils/errors";
import { paramsEuroFormatter } from "../../utils/formatters";

export const AdminReservationsPage: React.FC = () => {
  const [reservations, setReservations] = useState<ReservationRow[]>([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const gridRef = useRef<AgGridReact | any>();

  const {
    selectedPeriod,
    selectedYear,
    apartmentIdMap,
    selectedGroup,
    periodOptions,
    setSelectedPeriod,
    groupOptions,
    setSelectedGroup,
    yearOptions,
    setSelectedYear,
  } = useUserContext().context as AdminContextType;
  console.log("Batman");

  const handleFileUpload = (file: File) => {
    const formData = new FormData();
    console.log(file);
    formData.append("file", file);

    Swal.fire({
      title: "Uploading file...",
      allowOutsideClick: false,
    });
    Swal.showLoading();

    fetch(`${apiUrl}/api/admin/reservations/upload`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((response) => {
        if (response) {
          if (!response.ok) {
            return decodeErrorResponse(response);
          }
          Swal.close();
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((text) => {
        let importResult = Convert.toImportReservationsResponse(text).importReservationsResult;
        successPopup(
          `New Reservations: ${importResult.newReservations}\nUpdated Reservations: ${importResult.updatedReservations}\nDeleted Reservations: ${importResult.deletedReservations}`,
        );
        getAndSetReservations(selectedYear.toString(), selectedPeriod, selectedGroup.toString());
      })
      .catch((error) => {
        Swal.close();
        errorPopup(error.message);
      });
  };
  const getAndSetReservations = (year: string, period: string, group: string): void => {
    let url;
    console.log("foo");
    if (group === "0") {
      url = `${apiUrl}/api/admin/reservations?year=${year}&period=${period}`;
    } else {
      url = `${apiUrl}/api/admin/groups/${group}/reservations?year=${year}&period=${period}`;
    }
    fetch(url, fetchOptions)
      .then((response) => {
        if (response) {
          return response.text();
        }
        throw new Error("Unauthorized");
      })
      .then((response) => {
        let responseJson = Convert.toGetReservationsResponse(response);
        let res = responseJson.reservations.map((reservation: Reservation) => {
          return {
            id: reservation.id,
            apartment: apartmentIdMap.get(reservation.apartmentId)?.name || "N/A",
            platform: Platform[reservation.platform],
            reservationCode: reservation.reservationCode,
            bookingDate: reservation.bookingDate,
            startDate: reservation.startDate,
            endDate: reservation.endDate,
            actualDaysOfStay: reservation.actualDaysOfStay,
            guestName: reservation.guestName,
            numberOfGuests: reservation.numberOfGuests,
            receivableIncome: reservation.receivableIncome,
            amountPaid: reservation.amountPaid,
            paymentDate: reservation.paymentDate,
            commission: reservation.commission,
            cleaningFee: reservation.cleaningFee,
            cleaningCost: reservation.cleaningCost,
            hostingExpense: reservation.hostingExpense,
            epsilon: reservation.epsilon,
            netIncome: reservation.netIncome,
            grossAdr: reservation.grossAdr,
            netAdr: reservation.netAdr,
            vat: reservation.vat,
            sustainabilityFee: reservation.sustainabilityFee,
            temporaryStayDuty: reservation.temporaryStayDuty,
          };
        });
        setReservations(res);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getAndSetReservations(selectedYear.toString(), selectedPeriod, selectedGroup.toString());
  }, [selectedGroup, selectedPeriod, selectedYear, apartmentIdMap]);

  const strUpload = useCallback((rows: ReservationRow[]) => {
    const selectedRows: ReservationRow[] = gridRef.current.api.getSelectedRows();
    const reservationIds = selectedRows.map((reservation) => reservation.id);
  }, []);

  function myDataUpload(reservations: ReservationRow[]) {
    const selectedRows: ReservationRow[] = gridRef.current.api.getSelectedRows();
    const reservationIds = selectedRows.map((reservation) => reservation.id);
    Swal.fire({
      title: `Uploading ${reservations.length} reservations to cloudhotel`,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    fetch(`${apiUrl}/api/admin/reservations/mydata-upload`, {
      method: "POST",
      body: JSON.stringify({ reservationIds: reservationIds }),
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          return decodeErrorResponse(response);
        } else {
          Swal.close();
          return response.text();
        }
      })
      .then((response) => {
        let uploadResults = Convert.toUploadToMyDataResponse(response).myDataUploadResponse;
        let resultTableString = `
                    <table style="margin: auto; font-size: 0.8em; border-collapse: collapse;">
                            <tr style="border: 1px solid black;">
                                <th style="padding: 5px; border: 1px solid black;">Reservation Code</th>
                                <th style="padding: 5px; border: 1px solid black;">Outcome</th>
                                <th style="padding: 5px; border: 1px solid black;">Tax Invoice</th>
                                <th style="padding: 5px; border: 1px solid black;">Reservation Invoice</th>
                                <th style="padding: 5px; border: 1px solid black;">Errors</th>
                            </tr>
                        ${uploadResults
                          .map(
                            (result, i) => `
                    
                            <tr style="border: 1px solid black;">
                                <td style="padding: 5px; border: 1px solid black;">${selectedRows[i].reservationCode}</td>
                                <td style="padding: 5px; border: 1px solid black;">${result.error === "" ? "✔️" : "❌"}</td>
                                <td style="padding: 5px; border: 1px solid black;">${result.taxInvoiceUUID ? `<a href="https://cloudhotel.gr/public/invoice/${result.taxInvoiceUUID}">Link</a>` : ""}</td>
                                <td style="padding: 5px; border: 1px solid black;">${result.invoiceUUID ? `<a href="https://cloudhotel.gr/public/invoice/${result.invoiceUUID}">Link</a>` : ""}</td>
                                <td style="padding: 5px; border: 1px solid black;">${result.error}</td>
                            </tr>
                        `,
                          )
                          .join("")}
                    </table>`;
        successPopupWithHtml(resultTableString);
      })
      .catch((error) => {
        Swal.close();
        errorPopup(error.message);
      });
  }

  const gridDropdownFilters: GridDropdownFilter[] = [
    {
      label: "Period",
      options: periodOptions,
      selectedOption: selectedPeriod,
      onSelectionChanged: (period) => setSelectedPeriod(period as PeriodName),
    },
    {
      label: "Year",
      options: yearOptions,
      selectedOption: selectedYear.toString(),
      onSelectionChanged: (year) => setSelectedYear(Number(year)),
    },
    {
      label: "Group",
      options: groupOptions,
      selectedOption: selectedGroup.toString(),
      onSelectionChanged: (apartment) => setSelectedGroup(Number(apartment)),
    },
  ];

  const mydataUploadAction = {
    label: "myData",
    onClick: (rows: ReservationRow[]) => myDataUpload(rows),
    faIcon: faUpload,
  };

  const strUploadAction = {
    label: "Short Term Registry",
    onClick: (rows: ReservationRow[]) => strUpload(rows),
    faIcon: faUpload,
  };

  const reservationUploadButton = {
    label: "Import",
    onClick: () => setShowUploadModal(true),
    faIcon: faUpload,
  };

  const cleaningCostColumn = {
    field: "cleaningCost",
    headerName: "Cleaning Cost",
    cellStyle: { textAlign: "right" },
    valueFormatter: paramsEuroFormatter,
    width: 90,
    cellDataType: "number",
    type: ["rightAligned", "euroAmount"],
  };

  const hostingExpenseColumn = {
    field: "hostingExpense",
    headerName: "Hosting Expense",
    cellStyle: { textAlign: "right" },
    valueFormatter: paramsEuroFormatter,
    width: 90,
    cellDataType: "number",
    type: ["rightAligned", "euroAmount"],
  };

  return (
    <>
      <FileUploadModal
        show={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
        handleFileUpload={handleFileUpload}
      />
      <ReservationsPage
        reservations={reservations}
        gridRef={gridRef}
        reservationActions={[mydataUploadAction]}
        gridButtons={[reservationUploadButton]}
        gridDropdownFilters={gridDropdownFilters}
        extraColumnDefinitions={[hostingExpenseColumn, cleaningCostColumn]}
      />
    </>
  );
};
