import React, { useEffect, useState } from "react";
import Select, { SelectOption } from "../../lib/molecules/select";
import { GridDropdownFilter } from "../../lib/organisms/Grid";
import { AdminContextType, useUserContext } from "../../hooks/userUserContext";
import StatsCard from "../../lib/organisms/StatsCard";
import { dateToString } from "../../utils/periods";
import { FaIconName } from "../../utils/faIcons";
import { PieChartData } from "../../lib/organisms/PieChart";
import { overViewBarChartIncomeFormatter } from "../../utils/formatters";
import { Accordion, Alert } from "react-bootstrap";
import DonutChartCard from "../../lib/organisms/DonutChartCard";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useGetBookingPaceQuery } from "../../store/bookingManagementApi";
import {
  setEndDate,
  setPreviousEndDate,
  setPreviousStartDate,
  setPreviousYearEndDate,
  setPreviousYearStartDate,
  setStartDate,
  updateStats,
} from "../../store/bookingPaceSlice";

type BookingPaceStats = {
  grossIncome: number;
  netIncome: number;
  grossAdr: number;
  netAdr: number;
  daysBooked: number;
  futureDaysBooked: number;
  bookingsReceived: number;
};
const BookingPacePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    groupOptions,
    selectedGroup,
    setSelectedGroup,
    apartmentOptions,
    apartmentIdMap,
    selectedApartment,
    setSelectedApartment,
  } = useUserContext().context as AdminContextType;

  const {
    startDate,
    endDate,
    previousStartDate,
    previousEndDate,
    previousYearStartDate,
    previousYearEndDate,
    bookingPaceStats,
    bookingPaceStatsPrevious,
    bookingPaceStatsPreviousYear,
  } = useAppSelector((state) => state.bookingPace);

  const {
    data: currentData,
    isLoading: isLoadingCurrent,
    error: errorCurrent,
  } = useGetBookingPaceQuery({
    startDate: startDate.toISOString().split("T")[0],
    endDate: endDate.toISOString().split("T")[0],
  });

  const {
    data: previousData,
    isLoading: isLoadingPrevious,
    error: errorPrevious,
  } = useGetBookingPaceQuery({
    startDate: previousStartDate.toISOString().split("T")[0],
    endDate: previousEndDate.toISOString().split("T")[0],
  });

  const {
    data: previousYearData,
    isLoading: isLoadingPreviousYear,
    error: errorPreviousYear,
  } = useGetBookingPaceQuery({
    startDate: previousYearStartDate.toISOString().split("T")[0],
    endDate: previousYearEndDate.toISOString().split("T")[0],
  });

  const [apartmentOpts, setApartmentOpts] = useState<SelectOption[]>(apartmentOptions);

  useEffect(() => {
    setApartmentOpts(apartmentOptions);
  }, [apartmentOptions]);

  useEffect(() => {
    if (currentData) {
      dispatch(
        updateStats({
          data: currentData,
          type: "current",
          apartmentId: selectedApartment,
          groupId: selectedGroup,
        }),
      );
    }
  }, [currentData, selectedApartment, selectedGroup, dispatch]);

  useEffect(() => {
    if (previousData) {
      dispatch(
        updateStats({
          data: previousData,
          type: "previous",
          apartmentId: selectedApartment,
          groupId: selectedGroup,
        }),
      );
    }
  }, [previousData, selectedApartment, selectedGroup, dispatch]);

  useEffect(() => {
    if (previousYearData) {
      dispatch(
        updateStats({
          data: previousYearData,
          type: "previousYear",
          apartmentId: selectedApartment,
          groupId: selectedGroup,
        }),
      );
    }
  }, [previousYearData, selectedApartment, selectedGroup, dispatch]);

  const isLoading = isLoadingCurrent || isLoadingPrevious || isLoadingPreviousYear;
  const error = errorCurrent || errorPrevious || errorPreviousYear;

  const dropdownFilters: GridDropdownFilter[] = [
    {
      label: "Group",
      options: groupOptions,
      selectedOption: selectedGroup.toString(),
      onSelectionChanged: (group) => {
        setSelectedGroup(Number(group));
        if (Number(group) === 0) {
          setApartmentOpts(apartmentOptions);
          return;
        } else {
          const opts = apartmentOptions.filter((a) => apartmentIdMap.get(Number(a.value))?.groupId === Number(group));
          setApartmentOpts([{ label: "All", value: "0" }, ...opts]);
        }
      },
    },
    {
      label: "Apartment",
      options: apartmentOpts,
      selectedOption: selectedApartment.toString(),
      onSelectionChanged: (apartment) => setSelectedApartment(Number(apartment)),
    },
  ];

  const preparePieChartData = (
    stats: Map<string, BookingPaceStats>,
    key: keyof BookingPaceStats,
    valueFormatter: (val: number) => string = (value) => value.toFixed(),
  ): PieChartData[] => {
    return Array.from(stats.entries())
      .filter(([sourceName]) => sourceName !== "All Sources")
      .map(([sourceName, sourceStats]) => ({
        name: sourceName,
        value: sourceStats[key],
        valueFormatter,
      }))
      .sort((a, b) => b.value - a.value);
  };

  if (isLoadingCurrent || isLoadingPrevious || isLoadingPreviousYear) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  const numberOfDays = Math.ceil(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

  return (
    <>
      <div className="row d-flex justify-content-end">
        <div className="col-auto d-flex">
          {dropdownFilters.map((filter) => (
            <div key={filter.label} className="d-flex flex-column me-2">
              <Select
                color={"secondary"}
                onChange={(event) => filter.onSelectionChanged(event.target.value)}
                name={filter.label}
                value={filter.selectedOption}
                options={filter.options}
              />
            </div>
          ))}
        </div>
      </div>
      <span className="h3">Booking Pace</span>
      <div className="card col-12 col-xxl-5 mt-2">
        <div className="card-body">
          <div className="row">
            <div className={"d-flex justify-content-between small mb-0"}>
              <span>{`Bookings received between:`} </span>
              <span>
                <input
                  className={"border-0"}
                  type={"date"}
                  onChange={(e) => dispatch(setStartDate(new Date(e.target.value)))}
                  value={startDate?.toISOString().split("T")[0]}
                  max={endDate?.toISOString().split("T")[0]}
                ></input>
                -
                <input
                  className={"border-0"}
                  type={"date"}
                  onChange={(e) => dispatch(setEndDate(new Date(e.target.value)))}
                  value={endDate?.toISOString().split("T")[0]}
                  min={startDate?.toISOString().split("T")[0]}
                ></input>
              </span>
            </div>
          </div>
          {startDate && (
            <>
              <div className={"row"}>
                <div className={"d-flex justify-content-between small"}>
                  <span>{`vs Previous Period Dates:`} </span>
                  <span>
                    <input
                      className={"border-0"}
                      type={"date"}
                      onChange={(e) => dispatch(setPreviousStartDate(new Date(e.target.value)))}
                      value={previousStartDate?.toISOString().split("T")[0]}
                      max={previousEndDate?.toISOString().split("T")[0]}
                    ></input>
                    -
                    <input
                      className={"border-0"}
                      type={"date"}
                      onChange={(e) => dispatch(setPreviousEndDate(new Date(e.target.value)))}
                      value={previousEndDate?.toISOString().split("T")[0]}
                      min={previousStartDate?.toISOString().split("T")[0]}
                    ></input>
                  </span>
                </div>
              </div>
              <div className={"row"}>
                <div className={"d-flex justify-content-between small"}>
                  <span>{`vs Previous Year Dates:`} </span>
                  <span>
                    <input
                      className={"border-0"}
                      type={"date"}
                      onChange={(e) => dispatch(setPreviousYearStartDate(new Date(e.target.value)))}
                      value={previousYearStartDate?.toISOString().split("T")[0]}
                      max={previousYearEndDate?.toISOString().split("T")[0]}
                    ></input>
                    -
                    <input
                      className={"border-0"}
                      type={"date"}
                      onChange={(e) => dispatch(setPreviousYearEndDate(new Date(e.target.value)))}
                      value={previousYearEndDate?.toISOString().split("T")[0]}
                      min={previousYearStartDate?.toISOString().split("T")[0]}
                    ></input>
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="text-center mt-5">Loading...</div>
      ) : error ? (
        <Alert variant="danger">{error.toString()}</Alert>
      ) : bookingPaceStats.size === 0 ? (
        <Alert variant="info mt-5">No data available for the selected date range. Please try a different range.</Alert>
      ) : (
        <Accordion className={"mt-3"} defaultActiveKey={["All Sources"]} alwaysOpen>
          {Array.from(bookingPaceStats.entries())
            .sort(
              ([, a]: [string, BookingPaceStats], [, b]: [string, BookingPaceStats]) => b.grossIncome - a.grossIncome,
            )
            .map(([key, stats]: [string, BookingPaceStats]) => {
              const statsPrevious = bookingPaceStatsPrevious.get(key);
              const statsPreviousYear = bookingPaceStatsPreviousYear.get(key);
              return (
                <Accordion.Item eventKey={key} key={key}>
                  <Accordion.Header>{key}</Accordion.Header>
                  <Accordion.Body>
                    <div className="row g-4">
                      <div className="col-md-4">
                        <StatsCard
                          title="Gross Income"
                          value={stats?.grossIncome || 0}
                          lasPeriodValue={statsPrevious?.grossIncome || 0}
                          lastYearValue={statsPreviousYear?.grossIncome || 0}
                          periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                          faIconName={FaIconName.DollarSign}
                          valueFormatter={overViewBarChartIncomeFormatter}
                          faIconVariant="success"
                        />
                      </div>
                      <div className="col-md-4">
                        <StatsCard
                          title="Days Booked"
                          value={stats?.daysBooked || 0}
                          lasPeriodValue={statsPrevious?.daysBooked || 0}
                          lastYearValue={statsPreviousYear?.daysBooked || 0}
                          periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                          faIconName={FaIconName.CalendarDays}
                          faIconVariant="secondary"
                        />
                      </div>
                      <div className="col-md-4">
                        <StatsCard
                          title="Bookings Received"
                          value={stats?.bookingsReceived || 0}
                          lasPeriodValue={statsPrevious?.bookingsReceived || 0}
                          lastYearValue={statsPreviousYear?.bookingsReceived || 0}
                          periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                          faIconName={FaIconName.HashTag}
                          faIconVariant="info"
                        />
                      </div>

                      {key === "All Sources" && (
                        <>
                          <div className="col-md-4">
                            <DonutChartCard
                              data={preparePieChartData(
                                bookingPaceStats,
                                "grossIncome",
                                overViewBarChartIncomeFormatter,
                              )}
                              title="Gross Income Breakdown"
                              subtitle=""
                            />
                          </div>
                          <div className="col-md-4">
                            <div className={"d-flex justify-content-center flex-column"}>
                              <StatsCard
                                title="Average Daily Booking Rate"
                                value={stats?.daysBooked / numberOfDays || 0}
                                lasPeriodValue={(statsPrevious?.daysBooked || 0) / numberOfDays || 0}
                                lastYearValue={(statsPreviousYear?.daysBooked || 0) / numberOfDays || 0}
                                periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                                valueFormatter={(value) => value.toFixed(1)}
                                valueComment={`days booked per day`}
                                faIconName={FaIconName.CalendarDays}
                                faIconVariant="secondary"
                              />
                              <StatsCard
                                title="Future Days Booked"
                                value={stats?.futureDaysBooked || 0}
                                valueComment={`${(((stats?.futureDaysBooked ?? 0) / (stats?.daysBooked ?? 1)) * 100).toFixed()}% of days booked`}
                                lasPeriodValue={statsPrevious?.futureDaysBooked || 0}
                                lastYearValue={statsPreviousYear?.futureDaysBooked || 0}
                                periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                                faIconName={FaIconName.CalendarDays}
                                className={"mt-1"}
                              />
                              <StatsCard
                                title="Current Month - Days Booked"
                                value={(stats?.daysBooked ?? 0) - (stats?.futureDaysBooked ?? 0)}
                                valueComment={`${((((stats?.daysBooked ?? 0) - (stats?.futureDaysBooked ?? 0)) / (stats?.daysBooked ?? 1)) * 100).toFixed()}% of days booked`}
                                lasPeriodValue={
                                  (statsPrevious?.daysBooked ?? 0) - (statsPrevious?.futureDaysBooked ?? 0)
                                }
                                lastYearValue={
                                  (statsPreviousYear?.daysBooked ?? 0) - (statsPreviousYear?.futureDaysBooked ?? 0)
                                }
                                periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                                faIconName={FaIconName.CalendarDays}
                                className={"mt-1"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <DonutChartCard
                              data={preparePieChartData(bookingPaceStats, "daysBooked")}
                              title="Days Booked Breakdown"
                              subtitle=""
                            />
                          </div>
                        </>
                      )}

                      {key !== "All Sources" && (
                        <>
                          <StatsCard
                            title="Future Days Booked"
                            value={stats?.futureDaysBooked || 0}
                            valueComment={`${(((stats?.futureDaysBooked ?? 0) / (stats?.daysBooked ?? 1)) * 100).toFixed()}% of days booked`}
                            lasPeriodValue={statsPrevious?.futureDaysBooked || 0}
                            lastYearValue={statsPreviousYear?.futureDaysBooked || 0}
                            periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                            faIconName={FaIconName.CalendarDays}
                            className={"col-md-4"}
                          />
                          <StatsCard
                            title="Current Month - Days Booked"
                            value={(stats?.daysBooked ?? 0) - (stats?.futureDaysBooked ?? 0)}
                            valueComment={`${((((stats?.daysBooked ?? 0) - (stats?.futureDaysBooked ?? 0)) / (stats?.daysBooked ?? 1)) * 100).toFixed()}% of days booked`}
                            lasPeriodValue={(statsPrevious?.daysBooked ?? 0) - (statsPrevious?.futureDaysBooked ?? 0)}
                            lastYearValue={
                              (statsPreviousYear?.daysBooked ?? 0) - (statsPreviousYear?.futureDaysBooked ?? 0)
                            }
                            periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                            faIconName={FaIconName.CalendarDays}
                            className={"col-md-4"}
                          />
                        </>
                      )}
                      <div className="col-md-4">
                        <StatsCard
                          title="Net Income"
                          value={stats?.netIncome || 0}
                          lasPeriodValue={statsPrevious?.netIncome || 0}
                          lastYearValue={statsPreviousYear?.netIncome || 0}
                          periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                          faIconName={FaIconName.DollarSign}
                          faIconVariant="success"
                          valueFormatter={overViewBarChartIncomeFormatter}
                        />
                      </div>

                      <div className="col-md-4">
                        <StatsCard
                          title="Net Adr"
                          value={stats?.netAdr || 0}
                          lasPeriodValue={statsPrevious?.netAdr || 0}
                          lastYearValue={statsPreviousYear?.netAdr || 0}
                          periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                          faIconName={FaIconName.ChartLine}
                          faIconVariant="info"
                        />
                      </div>
                      <div className="col-md-4">
                        <StatsCard
                          title="Gross Adr"
                          value={stats?.grossAdr || 0}
                          lasPeriodValue={statsPrevious?.grossAdr || 0}
                          lastYearValue={statsPreviousYear?.grossAdr || 0}
                          periodDescription={`${dateToString(startDate)} - ${dateToString(endDate)}`}
                          faIconName={FaIconName.ChartLine}
                          faIconVariant="info"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      )}
    </>
  );
};

export default BookingPacePage;
