import Select from "../lib/molecules/select";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../layouts/adminLayout";
import logo from "../staticfiles/logo-no-border.png";
import ReportReservations from "../lib/organisms/reportReservations";
import ReportExpenses from "../lib/organisms/ReportExpenses";
import ReportDistributionStats from "../lib/organisms/reportSummary";
import ReportGraphs from "../lib/organisms/reportGraphs";
import { Apartment, ApartmentStats, Convert } from "../models/responses";
import { toPeriodName } from "../utils/periods";
import { useUserContext } from "../hooks/userUserContext";
import { PeriodName } from "../models";
import { fetchOptions } from "../hooks/useAuth";
import { Button } from "../lib/atoms/button/Button";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";

const ReportsPage = () => {
  const {
    periodOptions,
    yearOptions,
    ownerOptions,
    selectedPeriod,
    setSelectedPeriod,
    selectedYear,
    setSelectedYear,
    apartmentIdMap,
    selectedOwner,
    setSelectedOwner,
    ownerIdMap,
    expenseCategoriesMap,
  } = useUserContext().context;

  const [reportApartments, setReportApartments] = useState<Apartment[]>([]);
  const [apartmentStats, setApartmentStats] = useState<ApartmentStats[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedOwner !== undefined && selectedYear !== undefined && selectedPeriod !== undefined) {
      setIsLoading(true);
      let apartments: Apartment[] = [];
      apartmentIdMap.forEach((apartment, id) => {
        if (apartment.ownerIds.includes(selectedOwner)) {
          apartments.push(apartment);
        }
      });
      let getStatsForPeriodUrl = `${apiUrl}/api/owners/${selectedOwner}/stats?year=${selectedYear}&period=${selectedPeriod}`;
      fetch(getStatsForPeriodUrl, fetchOptions)
        .then((response) => {
          if (response) {
            return response.text();
          }
          throw new Error("Unauthorized");
        })
        .then((response) => {
          setApartmentStats(Convert.toGetStatsForApartmentsResponse(response).apartmentStats);
          setReportApartments(apartments);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [apartmentIdMap, selectedOwner, selectedYear, selectedPeriod]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value));
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPeriod(event.target.value as PeriodName);
  };

  const handleOwnerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOwner(Number(event.target.value));
  };
  if (isLoading || !selectedOwner || !selectedYear || !selectedPeriod) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="row d-flex justify-content-end align-items-end max-width-col-xl-9">
        <div className="col-auto d-flex justify-content-end ">
          <div className="d-flex flex-column me-2">
            <Select
              color={"secondary"}
              onChange={handlePeriodChange}
              name={"Period"}
              value={selectedPeriod}
              options={periodOptions}
            />
          </div>
          <div className="d-flex flex-column me-2">
            <Select
              color={"secondary"}
              onChange={handleYearChange}
              name={"Year"}
              value={selectedYear.toString()}
              options={yearOptions}
            />
          </div>
          <div className="d-flex flex-column me-2">
            <Select
              color={"secondary"}
              onChange={handleOwnerChange}
              name={"Owner"}
              value={selectedOwner.toString()}
              options={ownerOptions}
            />
          </div>
        </div>
        <div className={"col-auto d-flex"}>
          <div className="d-flex flex-column">
            <Button variant={"primary"} children={"Download"} faIcon={faDownload} onClick={() => window.print()} />
          </div>
        </div>
      </div>
      <div id="section-to-print" className="row justify-content-center mt-5">
        <div className="col-12 col-xl-9">
          <div className="card shadow border-0 p-4 p-md-5 position-relative">
            <div className="d-flex justify-content-between pb-2 pb-md-2 mb-2 mb-md-2 border-bottom border-light">
              <img className="image-lg" src={logo} alt="Keros Hospitality Logo" />
              <div>
                <h4>KEROS HOSPITALITY ΙΚΕ</h4>
                <ul className="list-group simple-list">
                  <li className="list-group-item fw-normal">Areos 43</li>
                  <li className="list-group-item fw-normal">Palaio Faliro, 17562, Greece</li>
                  <li className="list-group-item fw-normal">
                    <a className="fw-bold text-primary" href="mailto:keroshospitality@gmail.com">
                      keroshospitality@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mb-6 d-flex align-items-center justify-content-center">
              <h3 className="mb-0">
                {toPeriodName(selectedPeriod)} {selectedYear} Performance Report
              </h3>
            </div>
            <div className="row justify-content-between mb-4 mb-md-5">
              <div className="col-sm-8">
                <h5>Client Information</h5>
                <div>
                  <ul className="list-group simple-list">
                    <li className="list-group-item fw-normal">{ownerIdMap.get(selectedOwner)?.fullName || ""}</li>
                  </ul>
                </div>
              </div>
              <div className="col-sm col-lg-4 col-sm-4 text-sm-right">
                <div className="row">
                  <span className="col-6">
                    <strong>Date Issued:</strong>
                  </span>
                  <span className="col-6">
                    {new Date().toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <h5>Performance Overview</h5>
              <ReportGraphs
                yearAndPeriod={{ year: selectedYear, period: toPeriodName(selectedPeriod) }}
                apartments={reportApartments}
                apartmentStats={apartmentStats}
              />
            </div>
            <div className="row mt-3">
              <h5>Reservations</h5>
              <ReportReservations
                yearAndPeriod={{ year: selectedYear, period: toPeriodName(selectedPeriod) }}
                apartments={reportApartments}
                apartmentIdMap={apartmentIdMap}
              />
            </div>
            <div className="row mt-4">
              <h5>Expenses</h5>
              <ReportExpenses
                apartments={reportApartments}
                apartmentIdMap={apartmentIdMap}
                expenseCategories={expenseCategoriesMap}
                yearAndPeriod={{ year: selectedYear, period: toPeriodName(selectedPeriod) }}
              />
            </div>
            <div className="row mt-4">
              <h5>Summary</h5>
              <ReportDistributionStats
                apartmentStats={apartmentStats}
                yearAndPeriod={{
                  year: selectedYear,
                  period: toPeriodName(selectedPeriod),
                }}
                apartmentIdMap={apartmentIdMap}
                apartments={reportApartments}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsPage;
